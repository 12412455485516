// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'image',
    label: '',
    img: true,
    width: '120'
  }, {
    prop: 'host',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'address',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'postal_code',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'address_type',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
