/**
 * 团长地址相关接口
 */
import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/host_addresses/setup'
  })
}

export const getHostAddresses = (params) => {
  return request({
    method: 'GET',
    url: '/cms/host_addresses',
    params
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'get',
    url: `/cms/host_addresses/${id}/preview`
  })
}

export const putHostAddressesId = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/host_addresses/${id}`,
    data
  })
}

export const getHostAddressesId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/host_addresses/${id}`
  })
}

export const deleteHostAddresses = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/host_addresses/${id}`
  })
}
